import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

import { LoadingPage } from '../../components/LoadingPage'
import { ProductRedirectPageContext, usePageUrl } from '../../lib/pages'

export interface ProductPageRedirectTemplateProps {
  pageContext: ProductRedirectPageContext
}

const ProductPageRedirectTemplate: FC<ProductPageRedirectTemplateProps> = ({ pageContext }) => {
  const { navigate } = usePageUrl()
  const { targetUrlKey } = pageContext

  if (typeof window !== 'undefined') {
    // immediately redirect when rendered client-side
    navigate(`/products/${targetUrlKey}`, { replace: true })
  }

  return (
    <>
      {typeof window === 'undefined' && (
        <Helmet>
          <meta
            httpEquiv="refresh"
            content={`0;url=/products/${encodeURIComponent(targetUrlKey)}`}
          />
        </Helmet>
      )}
      <LoadingPage title="Loading..." />
    </>
  )
}

export default ProductPageRedirectTemplate
